import Vue from 'vue'
import Vuex from 'vuex'
// modules
import voivodeships from './modules/voivodeships'
import disciplines from './modules/disciplines'
import regulations from './modules/regulations'
import specialityCategories from './modules/specialityCategories'
import professionalCards from './modules/professionalCards'
import appraiserCards from './modules/appraiserCards'
import selectedSet from './modules/selectedSet'
import faqs from './modules/faqs'
import base from './modules/base'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    disciplines,
    regulations,
    specialityCategories,
    voivodeships,
    selectedSet,
    professionalCards,
    appraiserCards,
    faqs,
    base
  }
})
