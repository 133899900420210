import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    professionalCards: [],
    totalRecords: 0
  }),
  getters: {
    mapResults: state => {
      return state.professionalCards.map(el => {
        return {
          id: el.id,
          names: el.attributes?.decision?.names || '-',
          country: el.attributes?.postal_address?.country || '-',
          voivodeship: el.attributes?.postal_address?.voivodeship || '-',
          zip_code: el.attributes?.contact_data?.address?.zip_code || '-',
          post_office: el.attributes?.contact_data?.address?.post_office || '-',
          place: el.attributes?.contact_data?.address?.place_name || '-',
          street: el.attributes?.contact_data?.address?.street_name || '-',
          house_number: el.attributes?.contact_data?.address?.house_number || '-',
          apartment_number:
            el.attributes?.postal_address?.apartment_number || '-',
          primary_voivodeship:
            el.attributes?.personal_details?.primary_voivodeship || '-',
          professional_title:
            el.attributes?.personal_details?.professional_title || '-',
          academic_degree:
            el.attributes?.personal_details?.academic_degree || '-',
          memberships: el.attributes?.personal_details?.memberships || [],
          email_address: el.attributes?.contact_data?.address?.email_address || '-',
          phone_number: el.attributes?.contact_data?.address?.phone_number || '-',
          decision_number:
            el.attributes?.decision?.permission_number ||
            el.attributes?.decision?.decision_number ||
            '-',
          specialities:
            el.attributes?.specialities?.map(spec => {
              return {
                categories: spec?.speciality_categories,
                discipline: spec?.discipline,
                speciality: spec?.speciality,
                extent: spec?.extent,
                open: false
              }
            }) || []
        }
      })
    }
  },
  mutations: {
    SET_PROFESSIONAL_CARDS(state, payload) {
      state.professionalCards = payload
    },
    SET_TOTAL(state, totalRecords) {
      state.totalRecords = totalRecords
    }
  },
  actions: {
    getProfessionalCards({ commit }, params) {
      API.professionalCards.get(params).then(response => {
        if (response.data?.data?.length < 1) {
          this._vm.$toast.warning(API.professionalCards.empty)
          commit('base/SET_HIDDEN', null, { root: true })
          commit('SET_PROFESSIONAL_CARDS', [])
          commit('selectedSet/SET_NAMES', '', { root: true })
          commit('selectedSet/SET_DECISION', '', { root: true })
          commit('selectedSet/SET_REGION', null, { root: true })
          commit('selectedSet/SET_CATEGORY', null, { root: true })
          commit('selectedSet/SET_DISCIPLINE', null, { root: true })
          commit('selectedSet/SET_EXTENT', null, { root: true })
        } else {
          commit('SET_PROFESSIONAL_CARDS', response.data.data)
          commit('SET_TOTAL', response.data.meta?.stats?.total?.count)
          this.change()
        }
      })
    }
  }
}
