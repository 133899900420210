import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VModal from 'vue-js-modal'
import VueGtag from 'vue-gtag'
import './assets/tailwind.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import options from './toastOptions'

Vue.config.productionTip = false
Vue.use(VueSmoothScroll)
Vue.use(Toast, options)
Vue.use(VModal)
Vue.use(
  VueGtag,
  {
    config: { id: 'G-9ZWKM3TKHL' }
  },
  router
)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
