export default {
  namespaced: true,
  state: () => ({
    searching: false,
    hidden: true,
    result: false
  }),
  mutations: {
    SET_SEARCHING(state, searching) {
      state.searching = searching
    },
    SET_HIDDEN(state) {
      state.hidden = !state.hidden
    },
    SET_RESULT(state) {
      state.result = !state.result
    }
  }
}
