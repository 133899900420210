import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    faqs: []
  }),
  getters: {
    mapFaqs: state => {
      return state.faqs.map(el => {
        return {
          id: el.id,
          question: el.question,
          answear: el.answear,
          open: false
        }
      })
    }
  },
  mutations: {
    SET_FAQS(state, faqs) {
      state.faqs = faqs
    }
  },
  actions: {
    getFaqs({ commit }) {
      API.faqs.get().then(response => {
        commit('SET_FAQS', response.data)
      })
    }
  }
}
