import axios from 'axios'
import Vue from 'vue'
import i18n from './i18n'

const noRecords = i18n.t('emptyResponse')
const url = process.env.VUE_APP_BASE_URL
const qs = require('qs')

const client = axios.create({
  baseURL: url + 'api/public/',
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' })
})

client.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.status === 401) {
      Vue.$toast.warning(
        i18n.t('auth_fail')
      )
    } else if (error.response.status === 400) {
      Vue.$toast.warning(
        i18n.t('data_fail')
      )
    } else {
      const errorInfo = error.toJSON()
      Vue.$toast.error(
        i18n.t('download_fail') + '\n' + i18n.t('error_type') + errorInfo.message
      )
    }
    return Promise.reject(error)
  }
)

const disciplines = {
  get: params => client.get('disciplines', { params })
}
const faqs = {
  get: params => client.get('faqs', { params })
}
const regulations = {
  get: params => client.get('appraiser_regulation_specialities', { params })
}
const voivodeships = {
  get: params => client.get('voivodeships', { params })
}
const categories = {
  get: params => client.get('speciality_categories', { params })
}
const appraiserRegulationSpecialities = {
  get: params => client.get('appraiser_regulation_specialities', { params })
}
const professionalCards = {
  get: params => client.get('professional_cards', { params }),
  empty: i18n.t('emptyResponse')
}
const appraiserCards = {
  get: params => client.get('appraiser_cards', { params }),
  empty: i18n.t('emptyResponse')
}
const printoutVerifications = {
  post: (signature, token) =>
    client.post(
      'printout_verifications',
      { signature },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )
}

export default {
  disciplines,
  faqs,
  regulations,
  voivodeships,
  categories,
  appraiserRegulationSpecialities,
  professionalCards,
  appraiserCards,
  noRecords,
  printoutVerifications
}
